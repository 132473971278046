.nickmask {
  font-family: "Anurati-Regular", sans-serif;
  letter-spacing: 2px;
}

@media (max-width: 768px) {
  .nickmask {
    font-size: 50px;
  }
}
