.separator {
  margin-top: 3rem;
  margin-bottom: 3rem;
  border-width: 0;
  border-top: 1px solid #cabbbb;
  width: 100%;
  text-align: left;
  margin-left: 0;
}

.socialWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
}

.githubLogo {
  color: black;
  font-size: 18px;
  margin-left: 15px;
  margin-right: 30px;
  border: 0;
}

.linkedinLogo {
  color: black;
  font-size: 18px;
  border: 0;
}

.copywrite {
  font-size: 14px;
}

.nickmaskWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.nickmask {
  font-family: "Anurati-Regular";
  letter-spacing: 2px;
}
