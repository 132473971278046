body {
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Open Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
}

@font-face {
  font-family: "Anurati-Regular";
  src: local("Anurati-Regular"),
    url("./fonts/Anurati-Regular.otf") format("opentype");
}

h1 {
  font-size: 1.8rem;
  margin-top: 330px;
}

h2 {
  margin-top: 0;
}

h3 {
  font-size: 1.8rem;
  margin-top: 0;
}

a {
  color: #000;
  border-bottom: 1px solid #000;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin-top: 0;
}

ul li:before {
  content: "–";
  position: absolute;
  margin-left: -1.2em;
}
