.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
}

.col1 {
  width: 25%;
}

.col2,
.col3 {
  width: 35%;
}

.blurb {
  font-size: 14px;
}

@media (max-width: 650px) {
  .wrapper {
    flex-direction: column;
  }

  .col1,
  .col2,
  .col3 {
    width: 100%;
  }

  .col1,
  .col2 :last-child {
    margin-bottom: 0;
  }
}
