.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 100px;
}

.col1,
.col2 {
  width: 49%;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
  }

  .col1,
  .col2 {
    width: 100%;
  }

  .col1 :last-child {
    margin-bottom: 0;
  }
}
