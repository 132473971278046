.container {
  display: grid;

  grid-template-areas:
    ". header ."
    ". content ."
    ". footer .";

  grid-template-columns: auto minmax(auto, 1020px) auto;
  grid-template-rows: auto 1fr auto;
  grid-gap: 10px;

  height: 100vh;
}

@media (max-width: 768px) {
  .container {
    margin: 0 20px;
    grid-template-areas:
      "header"
      "content"
      "footer";

    grid-template-columns: 1fr;
    grid-template-rows:
      auto /* Header */
      1fr /* Content */
      auto; /* Footer */
  }
}

body {
  margin: 0;
  background-color: #e1f5e5;
}

header {
  grid-area: header;
  margin-top: 69px;
  font-size: 69px;
}

main {
  grid-area: content;
}

footer {
  grid-area: footer;
  height: 100px;
}
